<template>
  <div class="box">
    <el-table
      class="loading"
      v-if="loading"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      :style="{ width: '100%', height: '100%' }"
    >
    </el-table>
    <div>
      <el-row class="title">
        <el-col :span="14">
          <div class="left">商品信息</div>
        </el-col>
        <el-col :span="10">
          <div class="right">
            <span>单价</span>
            <span>数量</span>
            <span>合计</span>
          </div>
        </el-col>
      </el-row>
      <div class="item-box">
        <div v-for="(v, k) of OrderList" :key="k">
          <el-row class="centertitle">
            <el-col :span="6">
              <div class="left">
                <span>订单号:</span>
                <span @click="lookDetail(v)">{{ v.id }}</span>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="center">
                <div>
                  {{ v.createTime }}
                </div>
                
                <div >
                  <span>订单总价:</span>
                  <span class="price">{{
                    `￥${v.totalPrice}`
                  }}</span>
                </div>
                <div v-if="roleId != 1">
                  <span>买家公司:</span>
                  <span>{{companyName.companyName}}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="right">
                <span @click="lookDetail(v)"
                  >查看详情<i class="el-icon-arrow-right"></i
                ></span>
              </div>
            </el-col>
          </el-row>
          
          <div v-for="(val, k) of v.orderItemVO" :key="k">
            <el-row class="item-box-item">
              <el-col :span="10">
                <div class="left">
                  <img :src="val.productImg" alt="" />
                  <span>{{ val.productName }}</span>
                </div>
              </el-col>
              <el-col :span="4">
                 <div class="center">
                  <span>订单状态:</span>
                  <span>{{ val.state | state }}</span>
                </div>
              </el-col>
             
              <el-col :span="7">
                <div class="right">
                  <span>{{
                    `￥${val.totalPrice}`
                  }}</span>
                  <span>{{ val.productNum }}</span>
                  <span>{{ `￥${val.totalPrice}` }}</span>
                </div>
              </el-col>
            </el-row>
            
          </div>
          <div class="submit" v-if="v.state == 10">
            <!-- <span v-if="v.type!=1" @click="payBAITAO(v)">白条支付</span> -->
              <span @click="pay(v)">去支付</span>
          </div>
        </div>
      </div>
      <el-pagination
        :style="{ marginLeft: '600px', padding: '20px' }"
        class="el-pagination"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="currentchange1"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["OrderList", "total"],
  data() {
    return {
      // 是否显示加载中
      loading: false,
      pageNum: 1,
      pageSize: 5,
      companyName:JSON.parse(sessionStorage.getItem("userinfo")),
      roleId:sessionStorage.getItem('roleId')
    };
  },
  methods: {
    // 页面改变时的回调函数
    currentchange1(p) {
      console.log(this.OrderList)
      this.pageNum = p;
      // console.log(this.pageNum, this.pageSize);
      this.$emit("getOrderList",this.pageNum, this.pageSize);
      this.$emit("unpaidOrderList",this.pageNum, this.pageSize);
      this.$emit("prepaidOrderList",this.pageNum, this.pageSize);
      // console.log(this.pageNum, this.pageSize);
    }, // 支付
    async pay(v) {
      console.log(v,135);
      this.loading = true;
      const res = await this.$http({
        method: "post",
        url: `front/pay/scanCodePay`,
        data: {
          orderNo: v.id,
        },
      
      });
      console.log(v);
      console.log(res.data.data,'146');
      if (res.data.status) {
        this.$router.push({
          path: "/pay",
          query: {
            id: JSON.stringify(v.id),
            url: JSON.stringify(res.data.data.qrUrl),
          },
        });
        this.loading = false;
      }
    },
    // 白条支付
    payBAITAO(v){
      // payCreditBill
      console.log(v);
      this.loading = true;
      this.$router.push({
          path: "/payCreditBill",
          query: {
            id: JSON.stringify(v.id),
            // url: JSON.stringify(res.data.data.qrUrl),
          },
        });
    },
    //   查看详情
    lookDetail(item) {
      this.$router.push({
        path: "/orderListDetails",
        query: {
          item: JSON.stringify(item),
        },
      });
    },
    ww(){
      let aa = JSON.parse(sessionStorage.getItem("userinfo"));
      console.log(aa.companyName)
    }
  },
  computed: {
    // totalSon() {
    //   return this.OrderList.length;
    // },
  },
  filters: {
    state(val) {
      if (val == 0) {
        return "已取消";
      } else if (val == 10) {
        return "未支付";
      } else if (val == 20) {
        return "已付款";
      } else if (val == 40) {
        return "已发货";
      } else if (val == 50) {
        return "已完成";
      } else if (val == 60) {
        return "已关闭";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font: 12px/1.5 tahoma, arial, Hiragino Sans GB, \\5b8b\4f53, sans-serif;
}
.box {
  & ::v-deep .el-loading-mask {
    position: fixed !important;
    top: 0;
    left: 0;
  }
}
.submit {
  text-align: right;

  span {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    padding: 2px 10px;
    margin-top: 5px;
    margin-right: 10px;
    background-color: #c60023;
    color: #fff;
    cursor: pointer;
  }
}
.el-row > div:not(:nth-child(1)) {
  margin: 0;
}
.title {
  background: #eeeeee;
  color: #666;
  .left {
    margin-left: 150px;
    line-height: 45px;
  }
  .right {
    height: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    span {
      height: 100%;
      line-height: 45px;
    }
  }
}
.item-box {
  .centertitle {
    padding: 10px;
    background: #eeeeee;
    margin-top: 20px;
    .left {
      color: #999;
      & > span:nth-child(2) {
        font-weight: 700;
        cursor: pointer;
      }
      & > span:nth-child(2):hover {
        color: #e80012;
      }
    }
    .center {
      color: #999;
      display: flex;
      justify-content: space-around;
      .price {
        font-weight: 700;
        color: #c60023;
      }
    }
    .right {
      color: #999;
      text-align: right;
      & span {
        font-weight: 700;
        cursor: pointer;
        i {
          font-weight: 700;
        }
        &:hover {
          color: #e80012;
        }
      }
    }
  }
}
.item-box-item {
  border-bottom: 1px solid #eee;
  height: 100px;
  .left {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      margin: 9px;
      margin-left: 30px;
      border: 1px solid #ddd;
    }
    span {
      margin-left: 15px;
      color: #999;
    }
  }
  .center{
    display: flex;
    height: 100px;
    align-items: center;
    
  }
  .right {
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
</style>